import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Empty, Spin, Button } from 'antd';
import { Box, Row, Col } from '@WRAP_COMP';
import { setRoomDetail, setCurrentInRoomID } from '@/store/room';
import { useMutationApi, apiType } from '@/hook/useApi';
import useRoom from '@/hook/useRoom';
import useRoomMessage from '@/hook/useRoomMessage';
import useMe from '@/hook/useMe';
import useAlertMessage from '@/hook/useAlertMessage';
import useRecMessage from '@/hook/useRecMessage';

import UserDetailDrawer from '@/components/Drawer/UserDetailDrawer';
import ChatRoomHeader from '@/components/ChatRoomHeader/AdminHeader';
import ChatRoomContent from '@/components/ChatRoomContent';
import ChatRoomInputBar from '@/components/ChatRoomInputBar';
import MsgList from '@/components/MsgList';
import JoinCustomerServiceDrawer from './components/JoinCustomerServiceDrawer';
import InfoTabsBlock from './components/InfoTabsBlock';
import HeaderBtnGroup from './components/HeaderBtnGroup';
import PaymentListModal from './components/PaymentListModal';
import { AccountTypeKeys, CSRoomTypeKey, RoomStatusKeys } from '@/utils';
import ClientOrderDetailDrawer from '@/components/Drawer/ClientOrderDetailDrawer';
let disConnectedInterID = null;

function RoomDetailSection({
  currRoom = null,
  currTab = '0',
  setCurrRoom = () => {},
  onChangeTab = () => {},
  fetchMyConsHandle = () => {},
}) {
  const { meInfo, authRoutesKey } = useSelector(({ auth }) => auth);
  const { wsStatusInfo } = useSelector(({ subscribe }) => subscribe);
  const { isMonitorType } = useMe();
  const dispatch = useDispatch();
  const { onSuccessMsg, onErrorMsg } = useAlertMessage();

  const inputBarRef = useRef(null);
  const paymentListModalRef = useRef(null);
  const chatRoomContentRef = useRef(null);
  const clientOrderDetailDrawerRef = useRef(null);

  /**
   * 房間資訊
   *  */
  const { currentInRoomID, currentRoomID, roomDetail, roomUsersMap } =
    useSelector(({ room }) => room);
  const isDepositRoom = roomDetail?.room?.csType === CSRoomTypeKey['Deposit'];

  const userOrder = roomDetail?.room?.userOrder || {};

  const { getRoomLoading, fetchRoomInfo, updateInRoomHandle } = useRoom();

  const fetchRoomInfoHandle = useCallback(
    async (id, extraInfo) => {
      await fetchRoomInfo({ id, extraInfo });
    },
    [fetchRoomInfo],
  );

  const currInRoomHandle = (roomID = 0) => {
    updateInRoomHandle(roomID, roomID => dispatch(setCurrentInRoomID(roomID)));
  };

  /**
   * 房間對話訊息
   *  */
  const {
    roomMsg,
    setRoomMsgHandle,
    fetchRoomMsgHandle,
    fetchInRoomMsgHandle,
    pushMsg,
    loadMoreMsg,
    loadPreRoomMsg,
    lastReadID,
    postSystemMsg,
  } = useRoomMessage({
    currentRoomID,
    currUserRooms: roomDetail?.room.userRooms || [],
    currHistoryRooms: roomDetail?.room.historyRooms || [],
  });

  /**
   * 推訊息
   */
  const pushMsgHandle = msg => {
    pushMsg(msg);
    chatRoomContentRef.current.scrollNodeTo();
  };

  // 當前房間資訊 恢復預設
  const resetCurrRoomLocation = () => {
    dispatch(setRoomDetail(null));
    setCurrRoom(null);
    setRoomMsgHandle('RESET');
    setTypingTxt('');
    currInRoomHandle();
  };

  /**
   * initGetRoom
   * - 進入房間 updateInRoom 為預設 0
   * - 取得 roomDetail & roomMsg
   * initInRoom
   * - 進入房間 先等 ws 傳 Hi 才能 updateInRoom (即時押上後來訊息已讀用的)
   * - 更新最後已讀的訊息 updateLastMsg (直接取最後一筆訊息更新)
   * - 離開房間更新 updateInRoom 為 0
   */
  // 取得房間資訊(尚未進入房間)
  const initGetRoom = async (roomID, tabKey) => {
    await fetchRoomInfoHandle(roomID, { tabKey });
    fetchRoomMsgHandle(roomID);
    currInRoomHandle();
    setTypingTxt('');
  };
  // 取得房間資訊並進入房間
  const initInRoom = useCallback(
    async (roomID, tabKey) => {
      await fetchRoomInfoHandle(roomID, { tabKey });
      fetchInRoomMsgHandle(roomID);
      currInRoomHandle(roomID);
      setTypingTxt('');
      // ws 斷線處理
      clearDisConnectedInter();
      wsStatusHandle(wsStatusInfo);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wsStatusInfo],
  );

  const setHasNewMsgHandle = useCallback(
    recMsg => {
      if (recMsg.from.userID !== meInfo.id) {
        chatRoomContentRef.current?.setHasNewMsgHandle();
      }
    },
    [meInfo.id],
  );

  /** 取得歷史房間訊息 */

  /**
   * 加入其他客服
   */
  const [joinDrawer, setJoinDrawer] = useState({ visible: false });
  const visibleJoinDrawer = visible => setJoinDrawer({ visible });
  const [pullInRoom, { loading: pullLoading }] = useMutationApi(
    apiType.PULL_IN_CS_ROOM,
  );
  const pullInRoomHandle = async userIDs => {
    const [err] = await pullInRoom({
      in: {
        userIDs,
        roomID: roomDetail.room.roomID,
        isOperator: 'Yes',
      },
    });
    if (err) return;
    onSuccessMsg('操作成功，变更状态为咨询中');
    visibleJoinDrawer(false);
    if (userIDs.includes(meInfo.id) || currTab === '1') {
      onChangeTab('1'); // 到我的諮詢單
      initInRoom(roomDetail.room.roomID, '1');
    } else {
      onChangeTab('2'); // 到其他諮詢中
      initGetRoom(roomDetail.room.roomID, '2');
    }
  };

  /**
   * 離開房間
   *  */
  const [leaveRoom, { loading: leaveLdg }] = useMutationApi(
    apiType.LEAVE_GROUP,
  );
  const leaveRoomHandle = async roomID => {
    const [err] = await leaveRoom({ roomID });
    if (err) return;
    onSuccessMsg('已离开房间');
    onChangeTab(currTab);
    resetCurrRoomLocation();
  };

  /**
   * 封存房間
   */
  const [updateRoom, { loading: updateRoomLdg }] = useMutationApi(
    apiType.UPDATE_ROOM,
  );
  const csRoomStatusDeactivated = ({ roomID }) => {
    currTab !== '0' && onChangeTab(currTab);
    roomDetail?.room.roomID === roomID && resetCurrRoomLocation();
  };
  const deactivateRoomHandle = async id => {
    const [err] = await updateRoom({
      filter: { room: { id } },
      in: { status: RoomStatusKeys['Deactivated'] },
    });
    if (err) return;
    onSuccessMsg('已封存房间');
    csRoomStatusDeactivated({ roomID: id });
  };

  /**
   * 釘選房間
   */
  const [updateUserRoom, { loading: updateUserRoomLdg }] = useMutationApi(
    apiType.UPDATE_USER_ROOM,
  );
  const pinRoomHandle = useCallback(async () => {
    const isPinned = roomDetail.extraInfo.isPinned;
    const [err] = await updateUserRoom({
      filter: { userRoom: { roomID: roomDetail.room.roomID } },
      in: { isPinned: isPinned ? 'No' : 'Yes' },
    });
    if (!err) {
      dispatch(
        setRoomDetail({
          ...roomDetail,
          extraInfo: { ...roomDetail.extraInfo, isPinned: !isPinned },
        }),
      );
      fetchMyConsHandle();
    }
  }, [dispatch, roomDetail, updateUserRoom, fetchMyConsHandle]);

  /**
   * 加入
   */

  const [joinRoom, { loading: joinLd }] = useMutationApi(apiType.JOIN_CS_ROOM, {
    onSuccess: async ({ data }) => {
      await initInRoom(data.joinConsultingRoom.roomID, '1');
      onChangeTab('1'); // 到我的諮詢單
    },
  });

  /**
   * 生成訂單
   */
  const [postDeposit] = useMutationApi(apiType.deposit, {
    context: { isShowGeneralError: false },
    onSuccess: () => paymentListModalRef.current.onCancel(),
    onError: err => {
      const msgType = {
        400000: () => onErrorMsg('银行卡抽取失败，请联系客服'),
        400002: () => onErrorMsg('已有订单进行中'),
        402001: () => onErrorMsg('取消次数过多，请完成充值'),
        500002: () => onErrorMsg('呼叫第三方失败，请联系客服人员'),
      };
      msgType[err.message] ? msgType[err.message]() : onErrorMsg(err.message);
    },
  });
  const postDepositHandle = async inputs => {
    await postDeposit({
      in: {
        payeeID: inputs.payeeID,
        roomID: roomDetail.room.roomID,
        payType: inputs.payType,
        amount: "",
        timeExpired: 600,
        credential: '',
      },
    });
  };
  const postDepositHandleManul = async inputs => {
    await postDeposit({
      in: { payeeID: '-1', roomID: roomDetail.room.roomID, ...inputs },
    });
  };

  /**
   * ws 監聽
   */
  // 收到 Typing 正在輸入
  const [typingTxt, setTypingTxt] = useState('');
  const recTypingHandle = useCallback(({ typing }) => {
    const username = typing.username || typing.userID;
    const content = typing.content;
    setTypingTxt(`使用者 ${username} 正在輸入 ${content}`);
  }, []);
  const recMsgCommonType = useCallback(
    recMsg => {
      setHasNewMsgHandle(recMsg);
      setTypingTxt('');
    },
    [setHasNewMsgHandle],
  );
  const recNewMsg = useCallback(
    recMsg => {
      setHasNewMsgHandle(recMsg);
      setRoomMsgHandle('PUSH', { msgList: [recMsg] });
    },
    [setHasNewMsgHandle, setRoomMsgHandle],
  );

  useRecMessage({
    roomID: currentInRoomID,
    typing: recTypingHandle,
    msgCommonType: recMsgCommonType,
    orderStatusPending: recNewMsg,
    payList: recNewMsg,
    systemText: recNewMsg,
    csRoomStatusDeactivated,
  });

  /**
   * ws status 重連處理
   */
  const disConnectedInterHandle = (roomID = 0) => {
    // eslint-disable-next-line no-unused-vars
    if (!roomID) return;
    disConnectedInterID = setInterval(() => {
      fetchInRoomMsgHandle(roomID);
      fetchRoomInfoHandle(roomID);
    }, 8000);
  };
  const clearDisConnectedInter = () => {
    if (!disConnectedInterID) return;
    window.clearInterval(disConnectedInterID);
    disConnectedInterID = null;
  };
  const wsStatusHandle = ({ status }, roomID) => {
    const type = {
      onDisconnected: () => {
        if (roomID && !disConnectedInterID) {
          disConnectedInterHandle(roomID);
        }
      },
      onReconnected: clearDisConnectedInter,
      onConnected: clearDisConnectedInter,
    };
    type[status] && type[status]();
  };
  useEffect(() => {
    wsStatusHandle(wsStatusInfo, currentInRoomID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsStatusInfo]);

  useEffect(() => {
    if (currRoom?.room.roomID) {
      setRoomMsgHandle('RESET');
      currRoom.extraInfo.isExistInRoom
        ? initInRoom(currRoom.room.roomID, currTab)
        : initGetRoom(currRoom.room.roomID, currTab);
    }
    return () => {
      ['onReconnected', 'onConnected'].includes(wsStatusInfo.status) &&
        clearDisConnectedInter();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currRoom?.room.roomID]);

  if (!roomDetail) {
    return (
      <Box
        height='100%'
        justifyContent='center'
        alignItems='center'
        display='flex'>
        <Spin spinning={getRoomLoading}>
          <Empty description={false} />
        </Spin>
      </Box>
    );
  }

  const btnGroupRender = () => {
    if (!roomDetail.extraInfo.isExistInRoom) return null;
    return (
      <HeaderBtnGroup
        loading={leaveLdg || updateRoomLdg || updateUserRoomLdg}
        roomID={roomDetail.room.roomID}
        userRooms={roomDetail.room.userRooms}
        roomDetail={roomDetail}
        leaveRoom={leaveRoomHandle}
        deactivateRoom={deactivateRoomHandle}
        pinRoom={pinRoomHandle}
      />
    );
  };
  const frontDropControlRender = () => {
    return isMonitorType ? null : (
      <Button
        type='primary'
        size='large'
        style={{ maxWidth: '500px' }}
        block
        onClick={() => joinRoom({ roomID: roomDetail.room.roomID })} // 後續處理 > csRoomStatusProcessing
        loading={joinLd}
        disabled={
          getRoomLoading || !authRoutesKey.includes('API_JoinConsultingRoom')
        }>
        开始咨询
      </Button>
    );
  };

  return (
    <>
      <Box position='relative'>
        {roomDetail.ownerUserData ? (
          <Row>
            <Col height='90vh' span={14}>
              <Box height='100%' display='flex' flexDirection='column'>
                <ChatRoomHeader
                  title={`${roomDetail.ownerUserData?.username} #${roomDetail.ownerUserData?.userID}`}
                  subtitle={`房間ID #${roomDetail.room.roomID}`}
                  btnGroup={btnGroupRender()}
                  loading={getRoomLoading}
                />
                <ChatRoomContent
                  ref={chatRoomContentRef}
                  roomID={currentRoomID}
                  roomMsgLength={roomMsg.length}
                  loadMoreMsg={loadMoreMsg}
                  loadPreRoomMsg={loadPreRoomMsg}>
                  {typingTxt && <div>{typingTxt}</div>}
                  <MsgList
                    roomMsg={roomMsg}
                    roomID={currentRoomID}
                    lastReadID={lastReadID}
                    usersMap={roomUsersMap}
                    postSystemMsg={postSystemMsg}
                    setRoomMsgHandle={setRoomMsgHandle}
                    onOrderDetail={() =>
                      clientOrderDetailDrawerRef.current.setVisible(true)
                    }
                  />
                </ChatRoomContent>
                <ChatRoomInputBar
                  ref={inputBarRef}
                  showFrontDrop={!roomDetail.extraInfo.isExistInRoom}
                  frontDropControl={frontDropControlRender()}
                  pushMsg={pushMsgHandle}
                  // onClickPaymentChannel={() =>
                  //   paymentListModalRef?.current?.open()
                  // }
                  // showPaymentListBtn={isDepositRoom}
                  paymentChannelListBtn={
                    isDepositRoom && (
                      <PaymentListModal
                        ref={paymentListModalRef}
                        roomID={roomDetail.room.roomID}
                        amount={roomDetail.room.userOrder.amount}
                        payType={roomDetail.room.userOrder.payType}
                        onSubmit={inputs => postDepositHandle(inputs)}
                        onSubmitManual={inputs =>
                          postDepositHandleManul(inputs)
                        }
                      />
                    )
                  }
                  loading={getRoomLoading}
                />
              </Box>
            </Col>

            <Col height='90vh' span={10}>
              <InfoTabsBlock
                room={roomDetail.room}
                ownerUserData={roomDetail.ownerUserData}
                roomUsersMap={roomUsersMap}
                userDetailEdit={
                  roomDetail.ownerUserData?.accountType ===
                  AccountTypeKeys['CommonUser']
                }
                setCannedText={text =>
                  inputBarRef.current.setInputValue(p => p + text)
                }
                visibleJoinDrawer={visibleJoinDrawer}
              />
            </Col>
          </Row>
        ) : (
          <Box
            position='absolute'
            top='0'
            bottom='0'
            right='0'
            left='0'
            display='flex'
            justifyContent='center'
            alignItems='center'
            zIndex='9'
            bgColor='white'>
            <Empty description={<span>找不到拥有者</span>} />
          </Box>
        )}
      </Box>
      <JoinCustomerServiceDrawer
        visible={joinDrawer.visible}
        setVisible={visibleJoinDrawer}
        loading={pullLoading}
        submit={pullInRoomHandle}
      />
      <UserDetailDrawer user={roomDetail?.ownerUserData || {}} />
      <ClientOrderDetailDrawer
        ref={clientOrderDetailDrawerRef}
        {...userOrder}
      />
    </>
  );
}

export default RoomDetailSection;
