import { useState } from 'react';
import 'braft-editor/dist/index.css';
import Editor from 'braft-editor';
import { ContentUtils } from 'braft-utils';
import { useFetch } from '@/hook/useApi';
import { Upload, Button, Input, Modal } from 'antd';
import { useMutationApi, apiType } from '@/hook/useApi';
import ResetHtmlWrap from '@/components/ResetHtmlWrap';

const defaultState = Editor.createEditorState('<p>Hello <b>World!</b></p>');

const useEditor = ({ clickPreview = () => {} } = {}) => {
  const [editorState, setEditorState] = useState(defaultState);
  const changeEditorHandle = value => {
    setEditorState(value);
    // console.log('toHTML', value.toHTML());
  };

  const setHtmlToEditorState = htmlStr => {
    setEditorState(() => Editor.createEditorState(htmlStr));
    // console.log('htmlStr', htmlStr);
  };

  /**
   * 上傳圖片
   */
  const [putImageLoading, setPutImageLoading] = useState(false);
  const [createdUploadUrl] = useMutationApi(apiType.CREATED_UPLOAD_URL);
  const { fetchApi } = useFetch();
  const uploadHandler = async param => {
    if (!param.file) return false;
    setPutImageLoading(true);
    // 取得圖片上傳 url
    const [err, { data }] = await createdUploadUrl({
      in: { files: [{ fileName: param.file.name, size: 1, md5: '1' }] },
    });
    if (!err) {
      const url = data.createUploadURL.files[0].uploadURL;
      // const imgUrl = url.substring(0, url.indexOf('?'));
      const s3_domain = process.env.REACT_APP_S3_URL;
      const imgUrl = `${s3_domain}/${url.substring(
        url.indexOf('m/') + 2,
        url.indexOf('?'),
      )}`;

      try {
        const { error, data: putRes } = await fetchApi(url, {
          cusHeaders: { 'content-type': 'application/json' },
          method: 'PUT',
          body: param.file,
          resType: '',
        });
        if (error || !putRes.ok) return;
        setEditorState(() =>
          ContentUtils.insertMedias(editorState, [
            { type: 'IMAGE', url: imgUrl, width: '100%' },
          ]),
        );
      } catch (error) {
        console.log('uploadHandler put error', error);
      }
    }
    setPutImageLoading(false);
  };
  const uploadBtnRender = () => (
    <Upload
      accept='image/*'
      showUploadList={false}
      customRequest={uploadHandler}>
      <Button
        className='control-item button upload-button'
        loading={putImageLoading}>
        插入图片
      </Button>
    </Upload>
  );

  /**
   * 編輯 html
   */
  const [editHtmlModalVisible, setEditHtmlModalVisible] = useState(false);
  const [editHtml, setEditHtml] = useState('');
  const clickEditHTML = htmlStr => {
    setEditHtml(htmlStr);
    setEditHtmlModalVisible(true);
  };
  const onChangeHtml = e => setEditHtml(e.target.value);
  const clickSubmitHtml = () => {
    setHtmlToEditorState(editHtml);
    setEditHtmlModalVisible(false);
  };

  const EditHtmlModal = () => (
    <Modal
      title='编辑HTML'
      visible={editHtmlModalVisible}
      centered
      onCancel={() => setEditHtmlModalVisible(false)}
      footer={
        <Button variant='primary' onClick={clickSubmitHtml}>
          确认
        </Button>
      }>
      <Input.TextArea value={editHtml} onChange={onChangeHtml} />
    </Modal>
  );

  const extendControls = [
    {
      key: 'antd-uploader',
      type: 'component',
      component: uploadBtnRender(),
    },
    {
      key: 'editor-html',
      type: 'button',
      text: '编辑HTML',
      onClick: () => clickEditHTML(editorState.toHTML()),
    },
    {
      key: 'preview',
      type: 'button',
      text: '预览',
      onClick: () => clickPreview(editorState.toHTML()),
    },
  ];
  const editorControls = [
    'undo',
    'redo',
    'headings',
    'font-size',
    'line-height',
    'letter-spacing',
    'bold',
    'italic',
    'underline',
    'text-color',
    'remove-styles',
    'blockquote',
    'code',
    'hr',
    'separator',
    'link',
    'separator',
  ];

  return {
    editorControls,
    extendControls,
    editorState,
    setHtmlToEditorState,
    changeEditorHandle,
    EditHtmlModal,
  };
};

const PreviewModal = ({
  visible = false,
  onCancel = () => {},
  htmlStr = '',
}) => {
  return (
    <Modal
      title='预览项目内文'
      zIndex={2000}
      visible={visible}
      centered
      onCancel={onCancel}
      footer={null}>
      <ResetHtmlWrap html={htmlStr} />
    </Modal>
  );
};

export { useEditor, Editor, PreviewModal };
