import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
} from 'react';
import {
  Modal,
  Table,
  Button,
  Typography,
  Radio,
  Form,
  Input,
  Space,
  Select,
  Upload,
  Divider,
  Row,
} from 'antd';
import { useQueryApi, apiType, useMutationApi } from '@/hook/useApi';
import { PayType as PayTypeMap } from '@/utils';
import { Fragment } from 'react';
import useAlertMessage from '@/hook/useAlertMessage';
import { UploadOutlined } from '@ant-design/icons';
import useBankData from '@/hook/useBankData';
import SearchBar from '@/components/SearchBar';

function PaymentListModal(
  { roomID, amount, payType, onSubmit = () => {}, onSubmitManual = () => {} },
  ref,
) {
  const { onErrorMsg } = useAlertMessage();
  const bankData = useBankData();
  const currSelectedRow = useRef({});
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [paymentList, setPaymentList] = useState(false);
  const [innerPayType, setInnerPayType] = useState(payType);
  const [form] = Form.useForm();
  const [qrcode, setQrcode] = useState('');
  const [uploading, setUploading] = useState(false);
  const [isManualConfirmModalVisible, setIsManualConfirmModalVisible] =
    useState(false);
  const [fieldsValue, setFieldsValue] = useState({});
  const { fetchData, loading: listLoading } = useQueryApi(
    apiType.listPaymentChannel,
  );

  const listPaymentChannel = async ({ roomID, amount, payType, search }) => {
    const res = await fetchData({
      in: {
        roomID,
        amount,
        payType,
      },
    });
    const channels = res?.data?.listPaymentChannel?.channels || [];
    const filteredChannels = channels.filter(channel => {
      if (!search) return true;
      const arr =  Object.keys(search).reduce((acc, key) => {
        if (search[key]) {
          const tester = new RegExp(search[key], 'ig');
          acc.push(tester.test(channel[key]));
        } else {
          acc.push(true)
        }
        return acc;
      }, []);
      return !arr.includes(false)
    });
    setPaymentList(filteredChannels);
  };


  const onFinish = async search => {
    listPaymentChannel({ roomID, amount, payType, search });
  };

  const open = () => {
    setOpenModal(true);
    listPaymentChannel({ roomID, amount, payType });
  };

  const onCancel = () => {
    setOpenModal(false);
    currSelectedRow.current = {};
  };

  useImperativeHandle(ref, () => ({
    open,
    onCancel,
  }));

  const bank_name = useMemo(() => {
    return bankData.find(({ value }) => value === fieldsValue.bank_code)?.label;
  }, [fieldsValue, bankData]);

  const isBank = useMemo(() => {
    return innerPayType === PayTypeMap.bank.key;
  }, [innerPayType]);

  const searchFields = useMemo(
    () => [
      { name: 'name', type: 'text', label: '渠道名称' },
      { name: 'alias', type: 'text', label: '别名' },
    ],
    [],
  );

  const columns = {
    bank: [
      { key: 'name', title: '渠道名称', dataIndex: 'name' },
      { key: 'alias', title: '别名', dataIndex: 'alias' },
      { key: 'bank', title: '收款银行', dataIndex: 'bank' },
      { key: 'subBank', title: '支行名称', dataIndex: 'subBank' },
      { key: 'account', title: '收选账号', dataIndex: 'account' },
    ],
    wx: [
      { key: 'name', title: '渠道名称', dataIndex: 'name' },
      { key: 'alias', title: '别名', dataIndex: 'alias' },
    ],
    alipay: [
      { key: 'name', title: '渠道名称', dataIndex: 'name' },
      { key: 'alias', title: '别名', dataIndex: 'alias' },
    ],
    ecny: [
      { key: 'name', title: '渠道名称', dataIndex: 'name' },
      { key: 'alias', title: '别名', dataIndex: 'alias' },
    ],
  };

  const [newCreateUpload] = useMutationApi(apiType.NEW_CREATE_UPLOAD);
  const uploadHandler = async ({ file }) => {
    try {
      setUploading(true);
      const [err, { data }] = await newCreateUpload({
        in: file,
      });
      if (err) throw err;

      const url = data.newCreateUpload;
      const s3_domain = process.env.REACT_APP_S3_URL;
      const imgUrl = `${s3_domain}/${url}`;
      setQrcode(imgUrl);
    } catch (e) {
      onErrorMsg(e.message);
    } finally {
      setUploading(false);
    }
  };

  const onManualConfirm = async () => {
    setLoading(true);
    const inputs = {
      payType: innerPayType,
      amount: fieldsValue.innerAmount,
      timeExpired: Number(fieldsValue.timeExpired),
    };
    if (isBank) {
      inputs.credential = {
        type: PayTypeMap.bank.key,
        data: {
          account: fieldsValue.account,
          name: fieldsValue.name,
          open_bank: bank_name,
          open_bank_name: bank_name,
          open_bank_code: fieldsValue.bank_code,
          sub_bank: fieldsValue.sub_bank,
        },
      };
    } else {
      inputs.credential = {
        type: 'qrcode',
        url: '',
        q_url: qrcode,
        account: fieldsValue.account,
        account_name: fieldsValue.name,
      };
    }

    inputs.credential = JSON.stringify(inputs.credential);

    await onSubmitManual(inputs);
    setLoading(false);
    onCancel();
    setIsManualConfirmModalVisible(false);
  };

  const checkBankCard = async (account: string) => {
    fetch(
      `https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardNo=${account}&cardBinCheck=true`,
    )
      .then(res => res.json())
      .then(response => {
        if (response.validated) {
          form.setFieldsValue({
            bank_code: response.bank,
          });
        } else {
          form.setFieldsValue({
            bank_code: '',
          });
        }
      })
      .catch(e => {
        form.setFieldsValue({
          bank_code: '',
        });
      });
  };

  return (
    <>
      <Button type='primary' loading={loading} onClick={() => open()}>
        {payType === 'bank' ? '选择银行卡' : '选择支付渠道'}
      </Button>
      <Modal
        width={800}
        title='选择充值方式'
        visible={openModal}
        destroyOnClose={true}
        onCancel={onCancel}
        footer={null}>
        <Space direction='vertical'>
          <Space direction='vertical'>
            <Typography.Paragraph strong={true}>
              第三方充值渠道
            </Typography.Paragraph>
            <SearchBar
              fields={searchFields}
              onFinish={onFinish}
              loading={listLoading}
            />
            <Table
              loading={listLoading}
              rowSelection={{
                type: 'radio',
                onChange: (_, selectedRows) => {
                  currSelectedRow.current = selectedRows[0];
                },
              }}
              columns={columns[payType] || []}
              dataSource={paymentList}
              pagination={false}
              rowKey='payeeID'
            />
            <Row justify='end'>
              <Button
                type='primary'
                loading={loading}
                onClick={async () => {
                  setLoading(true);
                  await onSubmit(currSelectedRow.current);
                  setLoading(false);
                  onCancel();
                }}>
                确认
              </Button>
            </Row>
          </Space>

          <Divider />

          <Space direction='vertical'>
            <Typography.Paragraph strong={true}>手动添加</Typography.Paragraph>
            <Radio.Group
              onChange={e => setInnerPayType(e.target.value)}
              value={innerPayType}>
              <Radio value={PayTypeMap.bank.key}>{PayTypeMap.bank.label}</Radio>
              <Radio value={PayTypeMap.alipay.key}>
                {PayTypeMap.alipay.label}
              </Radio>
              <Radio value={PayTypeMap.wx.key}>{PayTypeMap.wx.label}</Radio>
            </Radio.Group>
            <Form
              form={form}
              name='control-hooks'
              initialValues={{ innerAmount: amount }}
              style={{ maxWidth: 600 }}>
              <Form.Item
                name='name'
                label='收款姓名'
                rules={[{ required: true, message: '必填' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name='innerAmount'
                label='收款金额'
                rules={[{ required: true, message: '必填' }]}>
                <Input />
              </Form.Item>
              {innerPayType === PayTypeMap.bank.key && (
                <Fragment>
                  <Form.Item
                    name='bank_code'
                    label='收款银行'
                    rules={[{ required: true, message: '必填' }]}>
                    <Select placeholder='请选择'>
                      {bankData.map(el => {
                        return (
                          <Select.Option key={el.value} value={el.value}>
                            {el.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item name='sub_bank' label='支行名称'>
                    <Input />
                  </Form.Item>
                </Fragment>
              )}
              <Form.Item
                key={`account-${innerPayType}`}
                name='account'
                label='收款账号'
                rules={isBank && [{ required: true, message: '必填' }]}>
                <Input
                  onBlur={e => {
                    if (innerPayType === PayTypeMap.bank.key) {
                      checkBankCard(e.target.value);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name='timeExpired'
                label='有效时间'
                rules={[{ required: true, message: '必填' }]}>
                <Input addonAfter='秒' />
              </Form.Item>
              {!isBank && (
                <Fragment>
                  <Row>
                    <Upload
                      accept='image/*'
                      customRequest={uploadHandler}
                      loading={uploading}
                      multiple={false}
                      showUploadList={false}>
                      <Button
                        className='control-item button upload-button'
                        loading={uploading}
                        icon={<UploadOutlined />}>
                        上传收款二维码
                      </Button>
                    </Upload>
                  </Row>
                  {qrcode && (
                    <Row>
                      <img
                        style={{
                          marginTop: '10px',
                          width: '200px',
                          height: 'auto',
                        }}
                        src={qrcode}
                        alt='withdraw-address-qrcode'
                      />
                    </Row>
                  )}
                </Fragment>
              )}
            </Form>
            <Row justify='end'>
              <Button
                type='primary'
                loading={loading || uploading}
                onClick={async () => {
                  const fields = await form.validateFields();
                  if (!isBank) {
                    if (!qrcode && !fields.account) {
                      onErrorMsg('请先填写收款帐号或上传收款二维码');
                      return;
                    }
                  }
                  setFieldsValue(fields);
                  setIsManualConfirmModalVisible(true);
                }}>
                确认
              </Button>
            </Row>
            <Modal
              title={PayTypeMap[innerPayType].label}
              visible={isManualConfirmModalVisible}
              onOk={onManualConfirm}
              onCancel={() => setIsManualConfirmModalVisible(false)}
              okButtonProps={{ loading }}
              okText='确认'
              cancelText='取消'>
              <Space direction='vertical'>
                <Typography.Text>收款姓名: {fieldsValue.name}</Typography.Text>
                <Typography.Text>
                  收款金额: {fieldsValue.innerAmount}
                </Typography.Text>
                {isBank && (
                  <Typography.Text>
                    收款银行: {bank_name}({fieldsValue.bank_code})
                  </Typography.Text>
                )}
                {isBank && (
                  <Typography.Text>
                    支行名称: {fieldsValue.sub_bank}
                  </Typography.Text>
                )}
                {!!fieldsValue.account && (
                  <Typography.Text>
                    收款账号: {fieldsValue.account}
                  </Typography.Text>
                )}
                <Typography.Text>
                  有效时间: {fieldsValue.timeExpired}秒
                </Typography.Text>
              </Space>
            </Modal>
          </Space>
        </Space>
      </Modal>
    </>
  );
}

export default forwardRef(PaymentListModal);
